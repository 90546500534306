<template>
	<div class="container mt-5">
		<div class="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content">
					<div class="modal-header" style="background-color: #fff100">
						<h5 class="modal-title" id="staticBackdropLabel"><b>Voter History</b></h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" aria-label="Close" style="color: #000"></button>
					</div>
					<div class="modal-body" v-if="voter_details">
						<table class="table table-striped table-hover" v-if="voter_details.history">
							<thead>
								<th># </th>
								<th>Contact Number 1</th>
								<th>Contact Number 2</th>
								<th>Address</th>
								<th>Ward No</th>
								<th>Updated By</th>
							</thead>
							<tbody>
								<tr v-for="data, index in voter_details.history" :key="index">
									<td>{{index + 1}}</td>
									<td :style="{ backgroundColor: (voter_details.verified_mobile_number == data.mobile_no1)?'#fff100':'' }">{{data.mobile_no1 ? data.mobile_no1 : "-"}}</td>
									<td :style="{ backgroundColor: (voter_details.verified_mobile_number == data.mobile_no2)?'#fff100':'' }">{{data.mobile_no2 ? data.mobile_no2 : "-"}}</td>
									<td :style="{ backgroundColor: (voter_details.verified_address == data.e_address)?'#fff100':'' }">{{data.e_address ? data.e_address : "-"}}</td>
									<td>{{data.ward_no ? data.ward_no : "-"}}</td>
									<td>
										{{ data.updatedBy 
											? (data.updatedBy.first_name || "") + " " + (data.updatedBy.middle_name || "") + " " + (data.updatedBy.last_name || "")
											: "-" }}
									</td>
								</tr>
								<tr>
									<td>{{voter_details.history.length + 1}}</td>
									<td :style="{ backgroundColor: (voter_details.verified_mobile_number == voter_details.mobile_no1)?'#fff100':'' }">{{voter_details.mobile_no1 ? voter_details.mobile_no1 : "-"}}</td>
									<td :style="{ backgroundColor: (voter_details.verified_mobile_number == voter_details.mobile_no2)?'#fff100':'' }">{{voter_details.mobile_no2 ? voter_details.mobile_no2 : "-"}}</td>
									<td :style="{ backgroundColor: (voter_details.verified_address == voter_details.e_address)?'#fff100':'' }">{{voter_details.e_address ? voter_details.e_address : "-"}}</td>
									<td>{{voter_details.ward_no ? voter_details.ward_no : "-"}}</td>
									<td>
										{{ voter_details.updatedBy 
											? (voter_details.updatedBy.first_name || "") + " " + (voter_details.updatedBy.middle_name || "") + " " + (voter_details.updatedBy.last_name || "")
											: "-" }}
									</td>
								</tr>
							</tbody>
						</table>
						<p class="text-center" v-else><strong>No History Found</strong></p>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content">
					<div class="modal-header" style="background-color: #fff100">
						<h5 class="modal-title" id="staticBackdropLabel"><b>Yadi Details - {{currentYadi}}</b></h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="color: #000"></button>
					</div>
					<div class="modal-body">
						<div class="mt-3">
							<div class="flex-row"></div>
						</div>
						<div class="table-responsive" style="height: 60vh; overflow-y: scroll;">
							<table class="table table-striped table-hover">
								<thead>
									<tr>
										<th>Sr.No</th> 
										<th>First Name</th>
										<th>Middle Name</th>
										<th>Last Name</th>
										<th>Voter Epic Number</th>
										<th>Mobile No 1</th>
										<th>Mobile No 2</th>
										<th>Address</th>
										<th>Verified Mobile Number</th>
										<th>Verified Address</th>
										<th>Receipt Contact Number</th>
										<th>Updated On</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="loading">
										<td colspan="13">
											<center>Loading...</center>
										</td>
									</tr>
									<tr v-for="(row, index) in dataset" :key="index">
										<td>{{row.srno?row.srno:"-"}}</td>
										<td>{{row.e_first_name?row.e_first_name:"-"}}</td>
										<td>{{row.e_middle_name?row.e_middle_name:"-"}}</td>
										<td>{{row.e_last_name?row.e_last_name:"-"}}</td>
										<td>{{row.vcardid?row.vcardid:"-"}}</td>
										<td>{{row.mobile_no1?row.mobile_no1:"-"}}</td>
										<td>{{row.mobile_no2?row.mobile_no2:"-"}}</td>
										<td>{{row.e_address?row.e_address:"-"}}</td>
										<td>{{row.verified_mobile_number?row.verified_mobile_number:"-"}}</td>
										<td>{{row.verified_address?row.verified_address:"-"}}</td>
										<td>{{row.contact?row.contact:"-"}}</td>
										<td>{{row.updatedAt?formatDate(row.updatedAt):"-"}}</td>
										<td>
											<button @click="voter(row)" data-bs-dismiss="modal" class="d-flex flex-row align-items-center btn btn-primary" style="text-decoration: none; cursor: pointer; background-color: #2e3a9e" data-bs-toggle="modal" data-bs-target="#staticBackdrop3">
												History
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header" style="background-color: #fff100">
						<h5 class="modal-title" id="staticBackdropLabel"><b>Sign In</b></h5>
					</div>
					<div class="modal-body">
						<form @submit.prevent="adminSignIn">
							<div class="mt-3">
								<label>Username</label>
								<input type="text" name="username" class="form-control" required v-model="username">
							</div>
							<div class="mt-3">
								<label>Password</label>
								<input type="password" name="username" class="form-control" v-model="password">
							</div>
							<div class="mt-3 mb-3">
								<button class="btn btn-primary" style="background-color: #2e3a9e">
									Sign In
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<form @submit.prevent="reports">
					<div class="row">
						<div class="col-md-3">
							<label>Select Constituency*</label>
							<select class="form-control" v-model="constituency" required>
								<option value="131">131</option>
								<option value="132">132</option>
								<option value="133">133</option>
							</select>
						</div>
						<div class="col-md-2">
							<label>Action</label><br>
							<button :disabled="loader" class="btn btn-primary" style="background-color: #2e3a9e">
								Get Analytics
							</button>
						</div>
					</div>
				</form>
			</div>
			<div class="col-md-12 mt-3 mb-3">
				<div class="row">
					<div class="col-md-3 mb-3">
						<div class="card">
							<div class="card-body">
								<h4>Ward Voters</h4>
								<div class="mt-3">
									<center>
										<h2>{{totalWardVoters}}</h2>
									</center>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-3">
						<div class="card">
							<div class="card-body">
								<h4>Total Voters</h4>
								<div class="mt-3">
									<center>
										<h2>{{totalVoters}}</h2>
									</center>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-3">
						<div class="card">
							<div class="card-body">
								<h4>Updated Voters</h4>
								<div class="mt-3">
									<center>
										<h2>{{updatedVoters}}</h2>
									</center>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-3">
						<div class="card">
							<div class="card-body">
								<h4>Un-Updated Voters</h4>
								<div class="mt-3">
									<center>
										<h2>{{unUpdatedVoters}}</h2>
									</center>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-3">
						<div class="card">
							<div class="card-body">
								<h4>Update Complete</h4>
								<div class="mt-3">
									<center>
										<h2>{{updatePercentage}} %</h2>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex flex-row-reverse" v-if="selectedYadis.length">
				<button :disabled="loading" @click="bulkDownload(selectedYadis)" data-bs-dismiss="modal" class="btn btn-primary" style="background-color: #2e3a9e; margin-left: 10px;">
					Download
				</button>
			</div>
			<div class="col-md-12 mt-3">
				<table class="table table-striped table-hover">
					<thead>
						<tr>
							<th scope="col">#</th>
							<th scope="col">Yadi Number</th>
							<th scope="col">Village</th>
							<th scope="col">Assigned</th>
							<th scope="col">Unassigned</th>
							<th scope="col">Total Voters</th>
							<th scope="col">Assigned Percentage</th>
							<th scope="col">Unassigned But Has Number</th>
							<th scope="col">Total Ward Voters</th>
							<th scope="col">Action</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, index) in tableData" :key="index">
							<td>
								<div class="form-check">
									<input 
										class="form-check-input" 
										type="checkbox" 
										:id="'row_'+index" 
										:checked="selectedYadis.includes(row.part_no)" 
										@change="toggleSelection(row.part_no)"
									>
								</div>
							</td>
							<td>{{ row.part_no }}</td>
							<td>{{ row.village }}</td>
							<td>{{ row.assignedCount }}</td>
							<td>{{ row.notAssignedCount }}</td>
							<td>{{ row.assignedCount + row.notAssignedCount }}</td>
							<td>{{ parseFloat((row.assignedCount/(row.assignedCount+row.notAssignedCount))*100).toFixed(2) }} %</td>
							<td>{{ row.notAssignedWithMobileCount }} </td>
							<td>{{ row.wardNoCount }} </td>
							<td>
								<button @click="submit(row.part_no)" data-bs-dismiss="modal" class="btn btn-primary" style="background-color: #2e3a9e" data-bs-toggle="modal" data-bs-target="#staticBackdrop2">
									View
								</button>
								<button :disabled="loading" @click="download(row.part_no)" data-bs-dismiss="modal" class="btn btn-primary" style="background-color: #2e3a9e; margin-left: 10px;">
									Download
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>

import axios from 'axios';
import crypto from 'crypto';
import * as XLSX from "xlsx";

export default {
	data() {
		return {
			tableData: [
				
			],
			token: "",
			modalInstance: null,
			constituency: null,
			loader: null,
			totalVoters: 0,
			updatedVoters: 0,
			unUpdatedVoters: 0,
			updatePercentage: 0,
			dataset: [],
			voter_details: {},
			loading: false,
			currentYadi: null,
			totalWardVoters: 0,
			downloadLoader: false,
			selectedYadis: []
		};
	},
	mounted() {
		const modalElement = document.getElementById('staticBackdrop1');
		if (modalElement) {
			this.modalInstance = new window.bootstrap.Modal(modalElement);
			this.modalInstance.show()
		}
	},
	methods: {
		toggleSelection(yadi) {
			if (this.selectedYadis.includes(yadi)) {
				this.selectedYadis = this.selectedYadis.filter(id => id !== yadi);
			} else {
				this.selectedYadis.push(yadi);
			}
		},
		formatDate(date) {
			if (!date) return "-"; // Return "-" if no date is provided

			const d = new Date(date*1000);
			const day = String(d.getDate()).padStart(2, '0');
			const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
			const year = d.getFullYear();
			const hours = String(d.getHours()).padStart(2, '0');
			const minutes = String(d.getMinutes()).padStart(2, '0');

			return `${day}-${month}-${year} ${hours}:${minutes}`;
		},
		adminSignIn() {
			const _data = {
				username: this.username,
				password: this.password
			}
			axios({
				method: "POST",
				url: "/api/v1/auth/admin",
				data: _data
			}).then((response) => {
				if(response.data.errCode == -1){
					this.token = response.data.token
					this.modalInstance.hide()
				}
				else{
					alert("Authentication Failed")
				}
			})
			.catch((err) => {
				console.log(err)
				alert("Server Error")
			})
		},
		reports() {
			this.loader = true
			this.tableData = []
			this.totalVoters = 0
			this.updatedVoters = 0
			this.unUpdatedVoters = 0
			this.updatePercentage = 0
			this.totalWardVoters = 0
			axios({
				method: "POST",
				url: "/api/v1/reports/summary-report",
				data: {
					constituency: this.constituency
				},
				headers: {
					authkey: this.token
				}
			}).then((response) => {
				if(response.data.errCode == 1){
					alert(response.data.errMsg)
				}
				else{
					const key = "74ce70de4186b559eb8c621e0d2f02c73453d63fef0fd7ded907b7b2a273dad5";
					const iv = "cb10ec48787215bca255b3b0f258fdf6";
					const commonKey = Buffer.from(key, 'hex');
					const commonIv = Buffer.from(iv, 'hex');

					// Create decipher
					let decipher = crypto.createDecipheriv('aes-256-cbc', commonKey, commonIv);

					// Decrypt data
					let decrypted = decipher.update(Buffer.from(response.data, 'hex'));
					decrypted = Buffer.concat([decrypted, decipher.final()]);

					// Return the decrypted data as a string
					const _data = decrypted.toString();
					this.tableData = JSON.parse(_data)
					for(var i=0; i<this.tableData.length; i++){
						this.totalVoters += (parseInt(this.tableData[i].assignedCount) + parseInt(this.tableData[i].notAssignedCount))
						this.updatedVoters += parseInt(this.tableData[i].assignedCount)
						this.unUpdatedVoters += parseInt(this.tableData[i].notAssignedCount)
						this.totalWardVoters += parseInt(this.tableData[i]["wardNoCount"])
					}
					this.updatePercentage = parseFloat((this.updatedVoters/this.totalVoters)*100).toFixed(2)
					this.loader = false
				}
			})
			.catch((err) => {
				alert("Server Error"+err)
				this.loader = false
			})
		},
		submit (yadi) {
			if(yadi != this.currentYadi){
				this.currentYadi = yadi
				this.loading = true
				this.dataset = []
				const data = {
					yadi_number: yadi,
					constituency: this.constituency
				}
				axios({
					method: 'POST',
					data: data,
					headers: {
						authkey: this.token
					},
					url: '/api/v1/search'
				})
				.then((response) => {
					if(response.data.errCode == 1){
						this.loading = false
					}
					else{
						const key = "74ce70de4186b559eb8c621e0d2f02c73453d63fef0fd7ded907b7b2a273dad5";
						const iv = "cb10ec48787215bca255b3b0f258fdf6";
						const commonKey = Buffer.from(key, 'hex');
						const commonIv = Buffer.from(iv, 'hex');

						// Create decipher
						let decipher = crypto.createDecipheriv('aes-256-cbc', commonKey, commonIv);

						// Decrypt data
						let decrypted = decipher.update(Buffer.from(response.data, 'hex'));
						decrypted = Buffer.concat([decrypted, decipher.final()]);

						// Return the decrypted data as a string
						const _data = decrypted.toString();
						this.dataset = JSON.parse(_data)
						this.loading = false
					}
				})
				.catch(() => {
					this.loading = false
				})
			}
		},
		download(yadi) {
			if(yadi != this.currentYadi){
				this.currentYadi = yadi
				this.loading = true
				this.dataset = []
				const data = {
					yadi_number: yadi,
					constituency: this.constituency
				}
				axios({
					method: 'POST',
					data: data,
					headers: {
						authkey: this.token
					},
					url: '/api/v1/search'
				})
				.then((response) => {
					if(response.data.errCode == 1){
						this.loading = false
					}
					else{
						const key = "74ce70de4186b559eb8c621e0d2f02c73453d63fef0fd7ded907b7b2a273dad5";
						const iv = "cb10ec48787215bca255b3b0f258fdf6";
						const commonKey = Buffer.from(key, 'hex');
						const commonIv = Buffer.from(iv, 'hex');

						// Create decipher
						let decipher = crypto.createDecipheriv('aes-256-cbc', commonKey, commonIv);

						// Decrypt data
						let decrypted = decipher.update(Buffer.from(response.data, 'hex'));
						decrypted = Buffer.concat([decrypted, decipher.final()]);

						// Return the decrypted data as a string
						const _data = decrypted.toString();
						this.dataset = JSON.parse(_data)
						const temp = []
						for(var i=0; i<this.dataset.length; i++){
							temp.push({
								"srno": this.dataset[i]["srno"],
								"vcardid": this.dataset[i]["vcardid"],
								"e_first_name": this.dataset[i]["e_first_name"],
								"e_middle_name": this.dataset[i]["e_middle_name"],
								"e_last_name": this.dataset[i]["e_last_name"],
								"l_first_name": this.dataset[i]["l_first_name"],
								"l_middle_name": this.dataset[i]["l_middle_name"],
								"l_last_name": this.dataset[i]["l_last_name"],
								"mobile_no1": this.dataset[i]["mobile_no1"],
								"mobile_no2": this.dataset[i]["mobile_no2"],
								"contact": this.dataset[i]["contact"],
								"e_address": this.dataset[i]["e_address"],
								"l_address": this.dataset[i]["l_address"],
								"building_details": this.dataset[i]["building_details"],
								"part_no": this.dataset[i]["part_no"],
								"ward_no": this.dataset[i]["ward_no"],
								"booth_no": this.dataset[i]["booth_no"],
								"l_boothaddress": this.dataset[i]["l_boothaddress"],
								"e_boothaddress": this.dataset[i]["e_boothaddress"]
							})
						}
						const wb = XLSX.utils.book_new();

						const ws = XLSX.utils.json_to_sheet(temp);

						// Append worksheet to the workbook
						XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

						// Generate Excel file and trigger download
						XLSX.writeFile(wb, `yadi-${yadi}.xlsx`);
						this.loading = false
					}
				})
				.catch(() => {
					this.loading = false
				})
			}
			else{
				this.loading = true
				const temp = []
						for(var i=0; i<this.dataset.length; i++){
							temp.push({
								"srno": this.dataset[i]["srno"],
								"vcardid": this.dataset[i]["vcardid"],
								"e_first_name": this.dataset[i]["e_first_name"],
								"e_middle_name": this.dataset[i]["e_middle_name"],
								"e_last_name": this.dataset[i]["e_last_name"],
								"l_first_name": this.dataset[i]["l_first_name"],
								"l_middle_name": this.dataset[i]["l_middle_name"],
								"l_last_name": this.dataset[i]["l_last_name"],
								"mobile_no1": this.dataset[i]["mobile_no1"],
								"mobile_no2": this.dataset[i]["mobile_no2"],
								"contact": this.dataset[i]["contact"],
								"e_address": this.dataset[i]["e_address"],
								"l_address": this.dataset[i]["l_address"],
								"building_details": this.dataset[i]["building_details"],
								"part_no": this.dataset[i]["part_no"],
								"ward_no": this.dataset[i]["ward_no"],
								"booth_no": this.dataset[i]["booth_no"],
								"l_boothaddress": this.dataset[i]["l_boothaddress"],
								"e_boothaddress": this.dataset[i]["e_boothaddress"]
							})
						}
						const wb = XLSX.utils.book_new();

						const ws = XLSX.utils.json_to_sheet(temp);

						// Append worksheet to the workbook
						XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

						// Generate Excel file and trigger download
						XLSX.writeFile(wb, `yadi-${yadi}.xlsx`);
			}
			this.loading = false
		},
		bulkDownload(){
			this.loading = true

			const data = {
				constituency: this.constituency,
				yadis: this.selectedYadis
			}

			axios({
				method: "POST",
				url: "/api/v1/reports/bulk-yadi-download",
				data: data,
				headers: {
					authkey: this.token
				},
				responseType: 'blob'
			})
			.then((response) => {
				const blob = response.data; // Ensure responseType: 'blob' is set in Axios
				const sanitizedFilename = `yadi-${this.selectedYadis.toString().replace(/[^a-zA-Z0-9_.-]/g, '_')}.zip`; // Sanitize the filename

				const url = window.URL.createObjectURL(blob); // Create a URL for the blob
				const a = document.createElement('a'); // Create a link element
				a.href = url;
				a.download = sanitizedFilename; // Set the filename for download
				document.body.appendChild(a); // Append the link to the DOM
				a.click(); // Trigger the download
				a.remove(); // Remove the link element after download

				// Revoke the Blob URL to free up memory
				window.URL.revokeObjectURL(url);
			})
			.catch((err) => {
				console.log(err)
			})

			this.loading = false
		},
		voter(row) {
			this.voter_details = row
		}
	}
};
</script>

<style scoped>
.table-container {
	max-width: 800px;
	margin: auto;
}
</style>
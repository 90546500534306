<template>
  <section>
    <div class="container" id="SignInPage">
      <div class="row d-flex flex-row align-items-center justify-content-center" style="margin-top: 20px">
        <div class="col-md-6">
          <!-- Error message display -->
          <p class="text-center text-danger" v-if="errMsg">{{ errMsg }}</p>
          <div class="row align-items-center justify-content-center">
            <div class="col-md-6">
              <div class="column align-items-center justify-content-center">
                <!-- Mobile Number Input -->
                <div class="mb-3">
                  <label for="mobileNumber"><b>Mobile Number</b></label>
                  <input
                    type="tel"
                    id="mobileNumber"
                    class="form-control"
                    name="mobileNumber"
                    placeholder="Enter your mobile number"
                    minlength="10"
                    v-model="number"
                    required
                  />
                </div>

                <div class="mb-3" v-if="!isValid">
                  <div class="g-recaptcha" :data-sitekey="siteKey" data-callback="onCaptchaVerified"></div>
                  <button class="btn btn-primary w-100 mt-3" :disabled="loading" style="background-color: #2e3a9e" @click="generateOTP">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                    Generate OTP
                  </button>
                </div>

                <!-- OTP Input -->
                <div class="mb-3" v-if="isValid">
                  <label for="otp"><b>OTP</b></label>
                  <input
                    type="text"
                    id="otp"
                    class="form-control"
                    name="otp"
                    placeholder="Enter the OTP"
                    minlength="6"
                    v-model="otp"
                    required
                  />
                </div>

                <div class="mb-3" v-if="isValid">
                  <button class="btn btn-primary w-100" :disabled="loading" style="background-color: #2e3a9e" @click="signIn">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                    Sign In
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import axios from 'axios';

export default {
  name: 'SignInPage',
  data() {
    return {
      siteKey: '6LfpGm4qAAAAAIj_HE0XboNrFMXNAGY1SNNd0Urm',
      number: '', // to bind mobile number input
      otp: '', // to bind OTP input
      isValid: false,
      loading: false, // to manage loading state
      errMsg: null, // to hold error messages
      recaptchaToken: null
    };
  },
  methods: {
    generateOTP() {
      const token = localStorage.getItem("recaptca")
      if(token){
        this.loading = true;
        axios({
          method: "POST",
          url: '/api/v1/auth/sign-in',
          data: {
            number: this.number,
            recaptchaToken: token
          }
        }).then((response) => {
          if(response.data.errCode == -1){
            this.isValid = true
            this.loading = false
          }
          else{
            this.errMsg = response.data.errMsg
            this.loading = false
          }
        }).catch(() => {
          this.errMsg = "Server Error"
        })
      }
      else{
        this.errMsg = "Please complete the reCAPTCHA"
      }
    },
    signIn() {
      this.loading = true;
      axios({
        method: "POST",
        url: '/api/v1/auth/validate',
        data: {
          number: this.number,
          otp: this.otp
        }
      }).then((response) => {
        if(response.data.errCode == -1){
          this.$router.push({ name: 'HomePage' });
          localStorage.setItem("token", response.data.token)
          this.loading = false
        }
        else{
          this.errMsg = response.data.errMsg
          this.loading = false
        }
      }).catch(() => {
        this.errMsg = "Server Error"
      })
    },
  },
};
</script>

<style>
</style>
<template>
	<div class="container mt-5">
		<div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header" style="background-color: #fff100">
						<h5 class="modal-title" id="staticBackdropLabel"><b>Sign In</b></h5>
					</div>
					<div class="modal-body">
						<form @submit.prevent="adminSignIn">
							<div class="mt-3">
								<label>Username</label>
								<input type="text" name="username" class="form-control" required v-model="username">
							</div>
							<div class="mt-3">
								<label>Password</label>
								<input type="password" name="username" class="form-control" v-model="password">
							</div>
							<div class="mt-3 mb-3">
								<button class="btn btn-primary" style="background-color: #2e3a9e">
									Sign In
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="mb-3 alert alert-solid" style="background-color: #2e3a9e; margin-top: 10px; margin-bottom: 10px;">
					<center>
						<span style="color: #fff">Add User</span>
					</center>
				</div>
				<form @submit.prevent="addUser">
					<div class="row">
						<div class="col-md-4 mt-3">
							<label>First Name</label>
							<input type="text" class="form-control" name="firstname" placeholder="First Name" required v-model="firstName">
						</div>
						<div class="col-md-4 mt-3">
							<label>Middle Name</label>
							<input type="text" class="form-control" name="firstname" placeholder="Middle Name" required v-model="middleName">
						</div>
						<div class="col-md-4 mt-3">
							<label>Last Name</label>
							<input type="text" class="form-control" name="firstname" placeholder="Last Name" required v-model="lastName">
						</div>
						<div class="col-md-4 mt-3">
							<label>Mobile Name</label>
							<input type="text" class="form-control" minlength="10" maxlength="10" placeholder="Mobile Number" pattern="[0-9]*" name="contact" v-model="mobileNumber">
						</div>
						<div class="col-md-4 mt-3">
							<label>Role</label>
							<select class="form-control" v-model="role">
								<option value="karyakartas">Office Portal</option>
								<option value="survey">Survey Portal</option>
								<option value="voters">Voters Portal</option>
								<option value="both">All Portal</option>
							</select>
						</div>
						<div class="col-md-4 mt-3 mb-3">
							<label>Action</label><br>
							<button class="btn btn-primary" style="background-color: #2e3a9e">
								Add User
							</button>
						</div>
					</div>
				</form>
			</div>
			<div class="col-md-12">
				<div class="mb-3 alert alert-solid" style="background-color: #2e3a9e; margin-top: 10px; margin-bottom: 10px;">
					<center>
						<span style="color: #fff">All Users</span>
					</center>
				</div>
				<div class="row">
					<div class="col-md-8">
						
					</div>
					<div class="col-md-4">
						<div class="d-flex flex-row justify-content-between">
							<input type="text" name="" class="form-control w-10" placeholder="Search" v-model="search">
							<button class="btn btn-primary" style="margin-left: 10px; background-color: #2e3a9e" :disabled="refreshing" @click="fetchUsers(search)">Refresh</button>
						</div>
					</div>
				</div>
				<table class="table table-striped table-hover">
					<thead>
						<tr>
							<th scope="col">Sr No</th>
							<th scope="col">Name</th>
							<th scope="col">Mobile Number</th>
							<th scope="col">Address</th>
							<th scope="col">Role</th>
							<th scope="col">Status</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, index) in paginatedData" :key="index">
							<td>{{ (index+1) * currentPage }}</td>
							<td>{{ row.first_name }} {{ row.middle_name }} {{ row.last_name }}</td>
							<td>{{ row.mobile_number? row.mobile_number:"-" }}</td>
							<td>{{ row.address?row.address:"-" }}</td>
							<td>
								<select @change="onRoleChange(row)" class="form-control" v-model="row.role">
									<option value="karyakartas">Office Portal</option>
									<option value="survey">Survey Portal</option>
									<option value="voters">Voters Portal</option>
									<option value="both">All Portal</option>
								</select>
							</td>
							<td>
								<select @change="onVerifcation(row)" class="form-control" v-model="row.is_verified">
									<option :value="1">Verified</option>
									<option :value="0">Un Verified</option>
								</select>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- Pagination controls -->
        <nav aria-label="Page navigation example">
					<div class="d-flex flex-row align-items-center justify-content-between">
						<p>Page {{currentPage}} of {{totalPages}}</p>
						<ul class="pagination">
							<!-- Previous Button -->
							<li class="page-item" :class="{ disabled: currentPage === 1 }">
								<a class="page-link" href="#" @click.prevent="goToPage(currentPage - 1)">Previous</a>
							</li>

							<!-- First Page with Ellipsis if Needed -->
							<template v-if="currentPage > 3">
								<li class="page-item" @click.prevent="goToPage(1)">
									<a class="page-link" href="#">1</a>
								</li>
								<li class="page-item disabled">
									<span class="page-link">...</span>
								</li>
							</template>

							<!-- Page Numbers (current, +/- 1 page for context) -->
							<li
								v-for="page in pageRange"
								:key="page"
								:class="{ active: page === currentPage }"
								class="page-item"
							>
								<a class="page-link" href="#" @click.prevent="goToPage(page)">{{ page }}</a>
							</li>

							<!-- Last Page with Ellipsis if Needed -->
							<template v-if="currentPage < totalPages - 2">
								<li class="page-item">
									<a class="page-link" href="#" @click.prevent="goToPage(currentPage)">{{currentPage}}</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#" @click.prevent="goToPage(currentPage+1)">{{currentPage+1}}</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#" @click.prevent="goToPage(currentPage+2)">{{currentPage+2}}</a>
								</li>
								<li class="page-item disabled">
									<span class="page-link">...</span>
								</li>
								<li class="page-item">
									<a class="page-link" href="#" @click.prevent="goToPage(totalPages)">{{ totalPages }}</a>
								</li>
							</template>

							<!-- Next Button -->
							<li class="page-item" :class="{ disabled: currentPage === totalPages }">
								<a class="page-link" href="#" @click.prevent="goToPage(currentPage + 1)">Next</a>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
</template>

<script>

import axios from 'axios';
import crypto from 'crypto';

export default {
	data() {
		return {
			tableData: [
				
			],
			rowsPerPage: 50,
			currentPage: 1,
			username: "",
			password: "",
			firstName: "",
			middleName: "",
			lastName: "",
			mobileNumber: "",
			token: "",
			role: "karyakartas",
			yadi_no: "",
			modalInstance: null,
			search: "",
			refreshing: false
		};
	},
	computed: {
		totalPages() {
			return Math.ceil(this.tableData.length / this.rowsPerPage);
		},
		paginatedData() {
			const start = (this.currentPage - 1) * this.rowsPerPage;
			const end = start + this.rowsPerPage;
			return this.tableData.slice(start, end);
		},
	},
	mounted() {
		const modalElement = document.getElementById('staticBackdrop1');
		if (modalElement) {
			this.modalInstance = new window.bootstrap.Modal(modalElement);
			this.modalInstance.show()
		}
	},
	methods: {
		goToPage(page) {
			if (page >= 1 && page <= this.totalPages) {
				this.currentPage = page;
			}
		},
		addUser() {
			const _data = {
				firstName: this.firstName,
				middleName: this.middleName,
				lastName: this.lastName,
				number: this.mobileNumber,
				role: this.role,
				yadi_no: this.yadi_no
			}
			axios({
				method: "POST",
				url: "/api/v1/auth/register",
				data: _data,
				headers: {
					authkey: this.token
				}
			}).then((response) => {
				if(response.data.errCode == -1){
					this.fetchUsers()
				}
				else{
					alert("Authentication Failed")
				}
			})
			.catch(() => {
				alert("Server Error")
			})
		},
		adminSignIn() {
			const _data = {
				username: this.username,
				password: this.password
			}
			axios({
				method: "POST",
				url: "/api/v1/auth/admin",
				data: _data
			}).then((response) => {
				if(response.data.errCode == -1){
					this.token = response.data.token
					this.modalInstance.hide()
					this.fetchUsers()
				}
				else{
					alert("Authentication Failed")
				}
			})
			.catch((err) => {
				console.log(err)
				alert("Server Error")
			})
		},
		fetchUsers(val) {
			this.refreshing = true
			axios({
				method: "POST",
				url: "/api/v1/auth/users",
				headers: {
					authkey: this.token
				},
				data: {
					search: val
				}
			}).then((response) => {
				if(response.data.errCode == 1){
					alert(response.data.errMsg)
				}
				else{
					const key = "74ce70de4186b559eb8c621e0d2f02c73453d63fef0fd7ded907b7b2a273dad5";
					const iv = "cb10ec48787215bca255b3b0f258fdf6";
					const commonKey = Buffer.from(key, 'hex');
					const commonIv = Buffer.from(iv, 'hex');

					// Create decipher
					let decipher = crypto.createDecipheriv('aes-256-cbc', commonKey, commonIv);

					// Decrypt data
					let decrypted = decipher.update(Buffer.from(response.data, 'hex'));
					decrypted = Buffer.concat([decrypted, decipher.final()]);

					// Return the decrypted data as a string
					const _data = decrypted.toString();
					this.tableData = JSON.parse(_data)
					this.refreshing = false
				}
			})
			.catch((err) => {
				alert("Server Error"+err)
				this.refreshing = false
			})
		},
		onVerifcation(body) {
			body['verified_at'] = new Date().getTime()/1000
			axios({
				method: "POST",
				url: "/api/v1/auth/update",
				headers: {
					authkey: this.token
				},
				data: body
			}).then((response) => {
				if(response.data.errCode == -1){
					alert("User Updated")
				}
				else{
					alert(response.data.errMsg)
				}
			})
			.catch((err) => {
				alert("Server Error"+err)
			})
		},
		onRoleChange(body) {
			body['role_change_on'] = new Date().getTime()/1000
			axios({
				method: "POST",
				url: "/api/v1/auth/update",
				headers: {
					authkey: this.token
				},
				data: body
			}).then((response) => {
				if(response.data.errCode == -1){
					alert("User Updated")
				}
				else{
					alert(response.data.errMsg)
				}
			})
			.catch((err) => {
				alert("Server Error"+err)
			})
		}
	},
	watch: {
		search(val) {
			if(val){
				if(val.length > 3){
					this.fetchUsers(val)
				}
			}
			else{
				this.fetchUsers(val)
			}
		}
	}
};
</script>

<style scoped>
.table-container {
  max-width: 800px;
  margin: auto;
}
</style>


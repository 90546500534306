<template>
  <section>
    <div class="container">
      <div class="mb-3 alert alert-solid" style="background-color: #2e3a9e; margin-top: 10px; margin-bottom: 10px;">
        <center>
          <span style="color: #fff">{{constinuency}} - {{label}}</span>
        </center>
      </div>
      <div class="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header" style="background-color: #fff100">
              <h5 class="modal-title" id="staticBackdropLabel"><b>Voter History</b></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="color: #000"></button>
            </div>
            <div class="modal-body" v-if="voter_details">
              <table class="table table-striped table-hover" v-if="voter_details.history">
                <thead>
                  <th># </th>
                  <th>Contact Number 1</th>
                  <th>Contact Number 2</th>
                  <th>Address</th>
                  <th>Ward No</th>
                  <th>Updated By</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  <tr v-for="data, index in voter_details.history" :key="index">
                    <td>{{index + 1}}</td>
                    <td :style="{ backgroundColor: (voter_details.verified_mobile_number == data.mobile_no1)?'#fff100':'' }">{{data.mobile_no1 ? data.mobile_no1 : "-"}}</td>
                    <td :style="{ backgroundColor: (voter_details.verified_mobile_number == data.mobile_no2)?'#fff100':'' }">{{data.mobile_no2 ? data.mobile_no2 : "-"}}</td>
                    <td :style="{ backgroundColor: (voter_details.verified_address == data.e_address)?'#fff100':'' }">{{data.e_address ? data.e_address : "-"}}</td>
                    <td>{{data.ward_no ? data.ward_no : "-"}}</td>
                    <td>
                      {{ data.updatedBy 
                          ? (data.updatedBy.first_name || "") + " " + (data.updatedBy.middle_name || "") + " " + (data.updatedBy.last_name || "")
                          : "-" }}
                    </td>
                    <td>
                      <div class="d-flex flex-row justify-content-between">
                        <button @click="updateVerifiedNumber(data.mobile_no1 ? data.mobile_no1 : '', voter_details.vcardid)" class="btn btn-primary" style="background-color: #2e3a9e; font-size: 10px;">
                          Mark Contact 1 As Verified
                        </button>&nbsp;&nbsp;
                        <button @click="updateVerifiedNumber(data.mobile_no2 ? data.mobile_no2 : '', voter_details.vcardid)" class="btn btn-primary" style="background-color: #2e3a9e; font-size: 10px;">
                          Mark Contact 2 As Verified
                        </button>&nbsp;&nbsp;
                        <button @click="updateVerifiedAddress(data.e_address ? data.e_address : '', voter_details.vcardid)" class="btn btn-primary" style="background-color: #2e3a9e; font-size: 10px;">
                          Mark Address As Verified
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{{voter_details.history.length + 1}}</td>
                    <td :style="{ backgroundColor: (voter_details.verified_mobile_number == voter_details.mobile_no1)?'#fff100':'' }">{{voter_details.mobile_no1 ? voter_details.mobile_no1 : "-"}}</td>
                    <td :style="{ backgroundColor: (voter_details.verified_mobile_number == voter_details.mobile_no2)?'#fff100':'' }">{{voter_details.mobile_no2 ? voter_details.mobile_no2 : "-"}}</td>
                    <td :style="{ backgroundColor: (voter_details.verified_address == voter_details.e_address)?'#fff100':'' }">{{voter_details.e_address ? voter_details.e_address : "-"}}</td>
                    <td>{{voter_details.ward_no ? voter_details.ward_no : "-"}}</td>
                    <td>
                      {{ voter_details.updatedBy 
                          ? (voter_details.updatedBy.first_name || "") + " " + (voter_details.updatedBy.middle_name || "") + " " + (voter_details.updatedBy.last_name || "")
                          : "-" }}
                    </td>
                    <td>
                      <div class="d-flex flex-row justify-content-between">
                        <button @click="updateVerifiedNumber(voter_details.mobile_no1 ? voter_details.mobile_no1 : '', voter_details.vcardid)" class="btn btn-primary" style="background-color: #2e3a9e; font-size: 10px;">
                          Mark Contact 1 As Verified
                        </button>&nbsp;&nbsp;
                        <button @click="updateVerifiedNumber(voter_details.mobile_no2 ? voter_details.mobile_no2 : '', voter_details.vcardid)" class="btn btn-primary" style="background-color: #2e3a9e; font-size: 10px;">
                          Mark Contact 2 As Verified
                        </button>&nbsp;&nbsp;
                        <button @click="updateVerifiedAddress(voter_details.e_address ? voter_details.e_address : '', voter_details.vcardid)" class="btn btn-primary" style="background-color: #2e3a9e; font-size: 10px;">
                          Mark Address As Verified
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="text-center" v-else><strong>No History Found</strong></p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="staticBackdrop5" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header" style="background-color: #fff100">
              <h5 class="modal-title" id="staticBackdropLabel"><b>Master Search</b></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="color: #000"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="globalSearching">
                <div class="row">
                  <div class="col-md-6"></div>
                  <div class="col-md-2">
                    <label>Name</label>
                    <input class="form-control" name="search" placeholder="Search by name" minlength="3" v-model="globalSearchName" />
                  </div>
                  <div class="col-md-2">
                    <label>Contact Number</label>
                    <input class="form-control" name="search" placeholder="Search by contact number" minlength="10" maxlength="10" v-model="globalSearchNumber" />
                  </div>
                  <div class="col-md-2">
                    <label>Action</label><br>
                    <button type="submit" class="btn btn-primary" style="background-color: #2e3a9e" :disabled="globalLoading">
                      Search
                    </button>&nbsp;&nbsp;
                    <button type="button" @click="globalClear()" class="btn btn-danger" :disabled="globalLoading">
                      Clear
                    </button>
                  </div>
                </div>
              </form>
              <div class="row mt-3" v-if="selectedRowElement.length">
                <div class="col-md-7"></div>
                <div class="col-md-4">
                  <label>Epic Number</label>
                  <input type="text" class="form-control" v-model="epicNumber" />
                </div>
                <div class="col-md-1">
                  <label>Action</label><br>
                  <button @click="assignEpicNumber()" class="btn btn-primary" :disabled="epicLoader" style="background-color: #2e3a9e">
                    Assign
                  </button>
                </div>
              </div>
              <table class="table table-striped table-hover mt-3">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Mobile No</th>
                    <th>Address</th>
                    <th>Source</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data, index in globalPaginatedData" :key="index" >
                    <td>
                      <div class="form-check">
                        <input 
                          class="form-check-input" 
                          type="checkbox" 
                          :checked="selectedRowElement.includes(data)" 
                          @change="toggleSelect(data, $event)" 
                          id="flexCheckDefault"
                        >
                      </div>
                    </td>
                    <td>{{data.name}}</td>
                    <td>{{data.mobile}}</td>
                    <td>{{data.address}}</td>
                    <td>{{data.source}}</td>
                  </tr>
                  <tr v-if="!globalPaginatedData.length && !globalLoading">
                    <td colspan="5" class="text-center">
                      No Data Available. Kindly provide appropriate search values
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" v-if="globalLoading">
                      <div class="align-items-center justify-content-center text-center">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- Pagination controls -->
              <nav aria-label="Page navigation example">
                <div class="d-flex flex-row align-items-center justify-content-between">
                  <p>Page {{globalCurrentPage}} of {{globalTotalPages}}</p>
                  <ul class="pagination">
                    <!-- Previous Button -->
                    <li class="page-item" :class="{ disabled: globalCurrentPage === 1 }">
                      <a class="page-link" href="#" @click.prevent="goToPageGlobal(globalCurrentPage - 1)">Previous</a>
                    </li>

                    <!-- First Page with Ellipsis if Needed -->
                    <template v-if="globalCurrentPage > 3">
                      <li class="page-item" @click.prevent="goToPageGlobal(1)">
                        <a class="page-link" href="#">1</a>
                      </li>
                      <li class="page-item disabled">
                        <span class="page-link">...</span>
                      </li>
                    </template>

                    <!-- Page Numbers (current, +/- 1 page for context) -->
                    <li
                      v-for="page in pageRange"
                      :key="page"
                      :class="{ active: page === globalCurrentPage }"
                      class="page-item"
                    >
                      <a class="page-link" href="#" @click.prevent="goToPageGlobal(page)">{{ page }}</a>
                    </li>

                    <!-- Last Page with Ellipsis if Needed -->
                    <template v-if="globalCurrentPage < globalTotalPages - 2">
                      <li class="page-item">
                        <a class="page-link" href="#" @click.prevent="goToPageGlobal(globalCurrentPage)">{{globalCurrentPage}}</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#" @click.prevent="goToPageGlobal(globalCurrentPage+1)">{{globalCurrentPage+1}}</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#" @click.prevent="goToPageGlobal(globalCurrentPage+2)">{{globalCurrentPage+2}}</a>
                      </li>
                      <li class="page-item disabled">
                        <span class="page-link">...</span>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#" @click.prevent="goToPageGlobal(globalTotalPages)">{{ globalTotalPages }}</a>
                      </li>
                    </template>

                    <!-- Next Button -->
                    <li class="page-item" :class="{ disabled: globalCurrentPage === globalTotalPages }">
                      <a class="page-link" href="#" @click.prevent="goToPageGlobal(globalCurrentPage + 1)">Next</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header" style="background-color: #fff100">
              <h5 class="modal-title" id="staticBackdropLabel"><b>Voter Details</b></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="color: #000"></button>
            </div>
            <div class="modal-body" v-if="voter_details">
              <form @submit.prevent="update">
                <label class="mt-3">
                  First Name
                </label>
                <input type="text" class="form-control" name="first_name" v-model="e_first_name" disabled>
                <label class="mt-3">
                  Middle Name
                </label>
                <input type="text" class="form-control" name="middle_name" v-model="e_middle_name" disabled>
                <label class="mt-3">
                  Last Name
                </label>
                <input type="text" class="form-control" name="last_name"  v-model="e_last_name" disabled>
                <label class="mt-3">
                  Contact Number
                </label>
                <input type="text" class="form-control" name="contact" v-model="mobile_no1">
                <label class="mt-3">
                  Ward Number
                </label>
                <input type="text" class="form-control" name="ward-number" v-model="ward_no">
                <label class="mt-3">
                  Survey By
                </label>
                <input type="text" class="form-control" name="ward-number" v-model="survey_by">
                <label class="mt-3">
                  Remark
                </label>
                <textarea class="form-control mt-3" name="address" v-model="remark">
                </textarea>
                <label class="mt-3">
                  Address
                </label>
                <textarea class="form-control mt-3" name="address" v-model="e_address">
                </textarea>
                <button :disabled="loading" type="submit" class="btn btn-primary mt-3 mb-3" style="background-color: #2e3a9e">
                  Update
                </button>
              </form>
              
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header" style="background-color: #fff100">
              <h5 class="modal-title" id="staticBackdropLabel"><b>Voter Details</b></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="color: #000"></button>
            </div>
            <div class="modal-body" v-if="voter_details">
              <p><b>Assembly:</b> {{constinuency}} - {{label}}</p>
              <p><b>First Name:</b> {{voter_details.e_first_name}}</p>
              <p><b>Middle Name:</b> {{voter_details.e_middle_name}}</p>
              <p><b>Last Name:</b> {{voter_details.e_last_name}}</p>
              <p><b>Booth Address:</b> {{voter_details.e_boothaddress || voter_details.l_boothaddress}}</p>
              <p><b>Part Number:</b> {{voter_details.part_no}}</p>
              <p><b>Serial Number:</b> {{voter_details.srno}}</p>
              <p><b>Voter Epic Number:</b> {{voter_details.vcardid}}</p>
              <div class="mt-3 d-flex align-items-center justify-content-center" style="width: 300px;">
                <input type="text" minlength="10" maxlength="10" name="sms_number" class="form-control" placeholder="SMS Number" v-model="sms_number">&nbsp;&nbsp;
                <a 
                  @click="update('sms')" 
                  data-bs-dismiss="modal" 
                  :href="generateSmsLink()" 
                  target="_blank"
                >
                  <button class="btn btn-primary" :disabled="loading" style="background-color: #2e3a9e">
                    Send
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header" style="background-color: #fff100">
              <h5 class="modal-title" id="staticBackdropLabel"><b>Voter Details</b></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="color: #000"></button>
            </div>
            <div class="modal-body" v-if="voter_details">
              <p><b>Assembly: </b> {{constinuency}} - {{label}}</p>
              <p><b>First Name: </b> {{voter_details.e_first_name}}</p>
              <p><b>Middle Name: </b> {{voter_details.e_middle_name}}</p>
              <p><b>Last Name: </b> {{voter_details.e_last_name}}</p>
              <p><b>Booth Address: </b> {{voter_details.e_boothaddress || voter_details.l_boothaddress}}</p>
              <p><b>Part Number: </b> {{voter_details.part_no}}</p>
              <p><b>Serial Number: </b> {{voter_details.srno}}</p>
              <p><b>Voter Epic Number: </b> {{voter_details.vcardid}}</p>
              <div class="mt-3 d-flex align-items-center justify-content-center" style="width: 300px;">
                <input type="text" minlength="10" maxlength="10" name="whatsapp_number" class="form-control" placeholder="Whatsapp Number" v-model="whatsapp_number">&nbsp;&nbsp;
                <a 
                  @click="update('whatsapp')" 
                  data-bs-dismiss="modal" 
                  :href="generateWhatsappLink()" 
                  target="_blank"
                >
                  <button class="btn btn-primary" :disabled="loading" style="background-color: #2e3a9e">
                    Send
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-between">
        <h4><strong>Filters:</strong></h4>
        <button data-bs-toggle="modal" data-bs-target="#staticBackdrop5" class="btn btn-primary" :disabled="loading" style="background-color: #2e3a9e">
          Master Search
        </button>
      </div>
      <form @submit.prevent="submit">
        <div class="row d-flex flex-row align-items-center justify-content-center">
          <div class="col-md-12">
            <p class="text-center text-danger" v-if="errMsg != null || errMsg != ''">{{errMsg}}</p>
            <div class="row">
              <div class="col-md-3 mb-3">
                <label><b>First Name</b></label>
                <input type="text" class="form-control" name="first_name" placeholder="First Name" minlength="3" v-model="first_name">
              </div>
              <div class="col-md-3 mb-3">
                <label><b>Middle Name</b></label>
                <input type="text" class="form-control" name="middle_name" placeholder="Middle Name" minlength="3" v-model="middle_name">
              </div>
              <div class="col-md-3 mb-3">
                <label><b>Last Name</b></label>
                <input type="text" class="form-control" name="last_name" placeholder="Last Name" minlength="3" v-model="last_name">
              </div>
              <div class="col-md-3 mb-3">
                <label><b>Voter Epic Number</b></label>
                <input type="text" class="form-control" name="voter_epic_number" placeholder="Voter Epic Number" minlength="3" v-model="voter_epic_number">
              </div>
              <div class="col-md-3 mb-3">
                <label><b>Area</b></label>
                <select class="form-control" @change="getYadis()" v-model="area">
                  <option v-for="value, index in areas" :key="index"> 
                    {{value}}
                  </option>
                </select>
              </div>
              <div class="col-md-3 mb-3">
                <label><b>Yadi Number</b></label>
                <select class="form-control" v-model="yadi_number">
                  <option v-for="value, index in yadis" :key="index"> 
                    {{value}}
                  </option>
                </select>
              </div>
              <div class="col-md-3 mb-3">
                <label><b>Ward Number</b></label>
                <select class="form-control" v-model="ward_no">
                  <option v-for="value, index in ward_nos" :key="index"> 
                    {{value}}
                  </option>
                </select>
              </div>
              <div class="col-md-3 mb-3">
                <label><b>Address</b></label>
                <input type="text" name="address" class="form-control" v-model="address">
              </div>
              <div class="col-md-3 mb-3">
                <label><b>Update By</b></label>
                <select class="form-control" v-model="update_by_status">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div class="col-md-3 mb-3">
                <label><b>Ward No Exists</b></label>
                <select class="form-control" v-model="ward_exists">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                  <option value="NA">NA</option>
                </select>
              </div>
              <div class="col-md-3 mb-3">
                <label>Action</label><br>
                <div class="d-flex flex-row">
                  <button class="btn btn-primary" :disabled="loading" style="background-color: #2e3a9e">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                    Search
                  </button>
                  <router-link :to="{name: 'HomePage'}">
                    <button class="btn btn-primary" style="margin-left: 10px; background-color: #2e3a9e">
                      Back
                    </button>
                  </router-link>
                  <button @click="clear()" type="button" class="btn btn-primary" style="margin-left: 10px; background-color: #2e3a9e">
                    Clear
                  </button>
                  <button @click="logout()" type="button" class="btn btn-primary" style="margin-left: 10px; background-color: #2e3a9e">
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <hr v-if="dataset.length" />
      <!---<div style="margin-top: 10px;">
        <div class="row">
          <div class=" col-md-4 d-flex align-items-stretch" v-for="data, index in dataset" :key="{index}">
            <div class="card card-body mb-3 d-flex flex-column mx-auto" style="border-color: #fff100;">
              <div class="d-flex flex-column align-items-center justify-content-between" style="height: 430px;">
                <div>
                  <p><b>Assembly: </b> {{constinuency}} - {{label}}</p>
                  <p><b>First Name: </b>{{data.l_first_name}} ({{data.e_first_name}}) </p>
                  <p><b>Middle Name: </b>{{data.l_middle_name}} ({{data.e_middle_name}})</p>
                  <p><b>Last Name: </b>{{data.l_last_name}} ({{data.e_last_name}})</p>
                  <p><b>Booth Address: </b> {{data.e_boothaddress || data.l_boothaddress}}</p>
                  <p><b>Part Number: </b> {{data.part_no}}</p>
                  <p><b>Serial Number: </b> {{data.srno}}</p>
                  <p><b>Voter Epic Number: </b> {{data.vcardid}}</p>
                </div>
                <div class="d-flex flex-row align-items-center justify-content-between" style="width: 100%">
                  <div @click="voter(data)" class="d-flex flex-row align-items-center" style="text-decoration: none; cursor: pointer;" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <img src="../assets/whatsapp.png" style="width: 16px; height: 16px;">
                    <span style="margin-left: 10px; color: #4caf50;"><b>Slip Via Whatsapp</b></span>
                  </div>
                  <div @click="voter(data)" class="d-flex flex-row align-items-center" style="text-decoration: none; cursor: pointer;" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">
                    <img src="../assets/sms.png" style="width: 16px; height: 16px;">
                    <span style="margin-left: 10px; color: #4caf50;"><b>Slip Via SMS</b></span>
                  </div>
                  <div @click="voter(data)" class="d-flex flex-row align-items-center" style="text-decoration: none; cursor: pointer;" data-bs-toggle="modal" data-bs-target="#staticBackdrop3">
                    <span style="margin-left: 10px; color: #4caf50;"><b>Update Details</b></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>--->
      <hr>
      <div class="row mb-3">
        <div class="col-md-8">
          <p>
            Total Voters: {{ dataset.length }}
          </p>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-10">
              <input type="text" name="wardno" class="form-control" placeholder="Ward No" v-model="mass_ward_no">
            </div>
            <div class="col-md-2">
              <button @click="updateWardNo()" class="btn btn-primary" :disabled="!selectedVoters.length || mass_update_ward_loader">
                Assign
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-10">
          <div class="d-flex flex-row flex-wrap align-items-center">
            <div class="form-check" :key="index" v-for="data, index in villages">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="flexCheckDefault" @change="toggleSelection1(data)" :checked="selected_village.includes(data)">
                <label class="form-check-label" for="flexCheckDefault">
                  {{data}}
                </label>
              </div>
            </div> 
          </div>
        </div>
        <div class="col-md-2">
          <button @click="download()" class="btn btn-primary" :disabled="!dataset.length || downloadLoader">
            Download
          </button>
        </div>
      </div>
      <hr />
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>
                <div class="form-check">
                  <input 
                    class="form-check-input" 
                    type="checkbox" 
                    :id="'row_'+index" 
                    @change="selectAll()"
                    :disabled="!dataset.length"
                  >
                </div>
              </th>
              <th>Sr.No</th>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Voter Epic Number</th>
              <th>Mobile No 1</th>
              <th>Mobile No 2</th>
              <th>Address</th>
              <th>Verified Mobile Number</th>
              <th>Verified Address</th>
              <th>Is Voter Verified</th>
              <th>Yadi Number</th>
              <th>Ward Number</th>
              <th>Updated On</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in dataset" :key="index">
              <th>
                <div class="form-check">
                  <input 
                    class="form-check-input" 
                    type="checkbox" 
                    :id="'row_'+index" 
                    :checked="selectedVoters.includes(row.vcardid)" 
                    @change="toggleSelection(row.vcardid)"
                  >
                </div>
              </th>
              <th>{{row.srno?row.srno:"-"}}</th>
              <th>{{row.e_first_name?row.e_first_name:"-"}}</th>
              <th>{{row.e_middle_name?row.e_middle_name:"-"}}</th>
              <th>{{row.e_last_name?row.e_last_name:"-"}}</th>
              <th>{{row.vcardid?row.vcardid:"-"}}</th>
              <th>{{row.mobile_no1?row.mobile_no1:"-"}}</th>
              <th>{{row.mobile_no2?row.mobile_no2:"-"}}</th>
              <th>{{row.e_address?row.e_address:"-"}}</th>
              <th>{{row.verified_mobile_number?row.verified_mobile_number:"-"}}</th>
              <th>{{row.verified_address?row.verified_address:"-"}}</th>
              <th>{{row.is_voter_verified?"Yes":"No"}}</th>
              <th>{{row.part_no?row.part_no:"-"}}</th>
              <th>{{row.ward_no?row.ward_no:"-"}}</th>
              <th>{{row.updatedAt?formatDate(row.updatedAt):"-"}}</th>
              <th>
                <button @click="voter(row)" class="d-flex flex-row align-items-center btn btn-primary" style="text-decoration: none; cursor: pointer; background-color: #2e3a9e" data-bs-toggle="modal" data-bs-target="#staticBackdrop4">
                  History
                </button>&nbsp;&nbsp;
                <button @click="voter(row)" class="d-flex flex-row align-items-center btn btn-primary" style="text-decoration: none; cursor: pointer; background-color: #2e3a9e" data-bs-toggle="modal" data-bs-target="#staticBackdrop3">
                  Update
                </button>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Pagination controls -->
      <!---<nav aria-label="Page navigation example">
        <ul class="pagination justify-content-end">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click.prevent="goToPage(currentPage - 1)">Previous</a>
          </li>
          <template v-if="showFirstEllipsis">
            <li class="page-item" @click.prevent="goToPage(1)">
              <a class="page-link" href="#">1</a>
            </li>
            <li class="page-item disabled">
              <span class="page-link">...</span>
            </li>
          </template>
          <li
            class="page-item"
            v-for="page in pages"
            :key="page"
            :class="{ active: page === currentPage }"
          >
            <a class="page-link" href="#" @click.prevent="goToPage(page)">{{ page }}</a>
          </li>
          <template v-if="showLastEllipsis">
            <li class="page-item disabled">
              <span class="page-link">...</span>
            </li>
            <li class="page-item" @click.prevent="goToPage(totalPages)">
              <a class="page-link" href="#">{{ totalPages }}</a>
            </li>
          </template>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click.prevent="goToPage(currentPage + 1)">Next</a>
          </li>
        </ul>
      </nav>--->
    </div>
  </section>
</template>

<script>

import axios from 'axios';
import crypto from 'crypto'; // Make sure your build system supports this


export default {
  name: 'SearchPage',
  data() {
    return {
      constinuency: this.$route.params.id,
      label: this.$route.params.name,
      address: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      voter_epic_number: "",
      loading: false,
      errMsg: '',
      dataset: [],
      voter_details: null,
      whatsapp_number: null,
      sms_number: null,
      e_first_name: null,
      e_middle_name: null,
      e_last_name: null,
      e_address: null,
      mobile_no1: null,
      ward_no: null,
      survey_by: null,
      modalInstance: null,
      booth_name: null,
      yadi_number: null,
      area: null,
      yadis: [],
      areas: [],
      ward_nos: [],
      rowsPerPage: 10,
      currentPage: 1,
      globalRowsPerPage: 10,
      globalCurrentPage: 1,
      remark: "",
      globalSearchName: "",
      globalSearchNumber: "",
      globalSearchData: [],
      globalLoading: false,
      selectedRowElement: [],
      epicLoader: false,
      epicNumber: null,
      selectedVoters: [],
      mass_ward_no: "",
      mass_update_ward_loader: false,
      update_by_status: "0",
      ward_exists: "NA",
      downloadLoader: false,
      selected_village: [],
      villages: []
    };
  },
  mounted() {
    const modalElement = document.getElementById('staticBackdrop3');
    if (modalElement) {
      this.modalInstance = new window.bootstrap.Modal(modalElement);
    }
    this.getAreas()
    this.getWardNo()
  },
  computed: {
    totalPages() {
      return Math.ceil(this.dataset.length / this.rowsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.dataset.slice(start, end);
    },
    globalTotalPages() {
      return Math.ceil(this.globalSearchData.length / this.rowsPerPage);
    },
    globalPaginatedData() {
      const start = (this.globalCurrentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.globalSearchData.slice(start, end);
    },
  },
  methods: {
    toggleSelection(vcardid) {
      if (this.selectedVoters.includes(vcardid)) {
        this.selectedVoters = this.selectedVoters.filter(id => id !== vcardid);
      } else {
        this.selectedVoters.push(vcardid);
      }
    },
    toggleSelection1(village) {
      if (this.selected_village.includes(village)) {
        this.selected_village = this.selected_village.filter(id => id !== village);
      } else {
        this.selected_village.push(village);
      }
    },
    selectAll() {
      if(this.selectedVoters.length == this.dataset.length){
        this.selectedVoters = []
      }
      else{
        var temp = []
        for(var i=0; i<this.dataset.length; i++){
          temp.push(this.dataset[i]['vcardid'])
        }
        this.selectedVoters = temp
      }
      
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    goToPageGlobal(page) {
      if (page >= 1 && page <= this.globalTotalPages) {
        this.globalCurrentPage = page;
      }
    },
    formatDate(date) {
      if (!date) return "-"; // Return "-" if no date is provided

      const d = new Date(date*1000);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = d.getFullYear();
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');

      return `${day}-${month}-${year} ${hours}:${minutes}`;
    },
    getAreas () {
      const data = {
        constituency: this.constinuency,
      }
      const key = localStorage.getItem('token')
      axios({
        method: "POST",
        data: data,
        headers: {
          authkey: key
        },
        url: "/api/v1/search/area"
      })
      .then((response) => {
        this.areas = response.data.data
        this.boothAddress = []
      })
      .catch((err) => {
        console.log(err)
      })
    },
    getWardNo () {
      const data = {
        constituency: this.constinuency
      }
      const key = localStorage.getItem('token')
      axios({
        method: "POST",
        data: data,
        headers: {
          authkey: key
        },
        url: "/api/v1/search/ward-no"
      })
      .then((response) => {
        this.ward_nos = response.data.data
      })
      .catch((err) => {
        console.log(err)
      })
    },
    getYadis () {
      const data = {
        constituency: this.constinuency,
        area: this.area
      }
      const key = localStorage.getItem('token')
      axios({
        method: "POST",
        data: data,
        headers: {
          authkey: key
        },
        url: "/api/v1/search/yadi-number"
      })
      .then((response) => {
        this.yadis = response.data.data
      })
      .catch((err) => {
        console.log(err)
      })
    },
    submit () {
      this.loading = true
      this.errMsg = ''
      this.selectedVoters = []
      if(this.first_name == "" && this.middle_name == "" && this.last_name == "" && this.voter_epic_number == "" && this.area == "" && this.booth_name == "" && this.yadi_number == ""){
        this.errMsg = 'Invalid Search. Please search by either first name, middle name, last name, voter epic number.'
        this.loading = false
        return true
      }
      if(this.first_name){
        if(this.first_name.length < 3){
          this.errMsg = 'Please enter atleast 3 characters for matching with first name'
          this.loading = false
          return true
        }
      }
      if(this.middle_name){
        if(this.middle_name.length < 3){
          this.errMsg = 'Please enter atleast 3 characters for matching with first name'
          this.loading = false
          return true
        }
      }
      if(this.last_name){
        if(this.last_name.length < 3){
          this.errMsg = 'Please enter atleast 3 characters for matching with first name'
          this.loading = false
          return true
        }
      }
      if(this.voter_epic_number){
        if(this.voter_epic_number.length < 3){
          this.errMsg = 'Please enter atleast 3 characters for matching with first name'
          this.loading = false
          return true
        }
      }
      if(this.booth_name){
        if(this.booth_name < 3){
          this.errMsg = 'Please enter atleast 3 characters for matching with booth name'
          this.loading = false
          return true
        }
      }
      if(this.area){
        if(this.area < 3){
          this.errMsg = 'Please enter atleast 3 characters for matching with area'
          this.loading = false
          return true
        }
      }
      if(this.address){
        if(this.address < 3){
          this.errMsg = 'Please enter atleast 3 characters for matching with area'
          this.loading = false
          return true
        }
      }
      this.currentPage = 1
      const data = {
        first_name: this.first_name?this.first_name.trim():"",
        last_name: this.last_name?this.last_name.trim():"",
        middle_name: this.middle_name?this.middle_name.trim():"",
        voter_epic_number: this.voter_epic_number?this.voter_epic_number.trim():"",
        constituency: this.constinuency?this.constinuency.trim():"",
        area: this.area?this.area.trim():"",
        yadi_number: this.yadi_number?this.yadi_number.trim():"",
        ward_no: this.ward_no,
        address: this.address,
        update_by_status: this.update_by_status,
        ward_exists: this.ward_exists
      }
      this.getVillages(data)
      const key = localStorage.getItem('token')
      axios({
        method: 'POST',
        data: data,
        headers: {
          authkey: key
        },
        url: '/api/v1/search'
      })
      .then((response) => {
        if(response.data.errCode == 1){
          this.errMsg = response.data.errMsg
          this.loading = false
        }
        else{
          const key = "74ce70de4186b559eb8c621e0d2f02c73453d63fef0fd7ded907b7b2a273dad5";
          const iv = "cb10ec48787215bca255b3b0f258fdf6";
          const commonKey = Buffer.from(key, 'hex');
          const commonIv = Buffer.from(iv, 'hex');

          // Create decipher
          let decipher = crypto.createDecipheriv('aes-256-cbc', commonKey, commonIv);

          // Decrypt data
          let decrypted = decipher.update(Buffer.from(response.data, 'hex'));
          decrypted = Buffer.concat([decrypted, decipher.final()]);

          // Return the decrypted data as a string
          const _data = decrypted.toString();
          this.dataset = JSON.parse(_data)
          this.loading = false
        }
      })
      .catch(() => {
        this.errMsg = "Something went wrong. Please try again"
        this.loading = false
      })
    },
    getVillages(data) {
      const key = localStorage.getItem('token')
      axios({
        method: "POST",
        url: "/api/v1/search/villages",
        data: data,
        headers: {
          authkey: key
        }
      })
      .then((response) => {
        this.villages = response.data
      })
      .catch((err) => {
        console.log(err)
      })
    },
    download() {
      this.downloadLoader = true;

      // Prepare the request data
      const data = {
        first_name: this.first_name?this.first_name.trim():"",
        last_name: this.last_name?this.last_name.trim():"",
        middle_name: this.middle_name?this.middle_name.trim():"",
        voter_epic_number: this.voter_epic_number?this.voter_epic_number.trim():"",
        constituency: this.constinuency?this.constinuency.trim():"",
        area: this.selected_village,
        yadi_number: this.yadi_number?this.yadi_number.trim():"",
        ward_no: this.ward_no,
        address: this.address,
        update_by_status: this.update_by_status,
        ward_exists: this.ward_exists
      };

      // Retrieve the authentication key from localStorage
      const key = localStorage.getItem('token');

      // Send the request using Axios
      axios({
        method: 'POST',
        data: data,
        headers: {
          authkey: key
        },
        url: '/api/v1/reports/ward-report',
        responseType: 'blob' // Ensure binary data is handled properly
      })
      .then((response) => {
        // Handle error case (assuming error code 1 indicates failure)
        if (response.data.errCode === 1) {
          alert(response.data.errMsg); // Display error message
          this.downloadLoader = false; // Stop loader
        } else {
          // Handle success case: download the file
          const blob = response.data;
          const url = window.URL.createObjectURL(blob); // Create a URL for the blob
          const a = document.createElement('a'); // Create a link element
          a.href = url;
          a.download = 'master-report.xlsx'; // Name of the file to download
          a.click(); // Trigger download
          this.downloadLoader = false; // Stop loader
        }
      })
      .catch((err) => {
        // Handle error in the request
        alert(`Something went wrong. Please try again. Error: ${err}`);
        this.downloadLoader = false; // Stop loader
      });
    },
    voter (item) {
      this.voter_details = item
      this.e_last_name = item.e_last_name
      this.e_first_name = item.e_first_name
      this.e_middle_name = item.e_middle_name
      this.mobile_no1 = item.mobile_no1
      this.e_address = item.e_address
      this.whatsapp_number = null
      this.sms_number = null
      this.ward_no = item.ward_no
      this.survey_by = item.survey_by
      this.remark = item.remark
    },
    update () {
      const key = localStorage.getItem('token')
      const body = {}
      if(this.e_first_name){
        body['e_first_name'] = this.e_first_name
      }
      if(this.e_middle_name){
        body['e_middle_name'] = this.e_middle_name
      }
      if(this.e_last_name){
        body['e_last_name'] = this.e_last_name
      } 
      if(this.mobile_no1){
        body['mobile_no1'] = this.mobile_no1
      } 
      if(this.e_address){
        body['e_address'] = this.e_address
      }
      if(this.whatsapp_number || this.sms_number){
        body['mobile_no1'] = this.whatsapp_number || this.sms_number
      }
      if(this.ward_no){
        body['ward_no'] = this.ward_no
      }
      if(this.survey_by){
        body['survey_by'] = this.survey_by
      }
      if(this.remark){
        body['remark'] = this.remark
      }
      this.loading = true
      axios({
        method: 'POST',
        url: '/api/v1/update',
        data: {
          constituency: this.constinuency,
          vcardid: this.voter_details.vcardid,
          body: body
        },
        headers: {
          authkey: key
        }
      })
      .then((response) => {
        if(response.data.errCode == -1){
          if(this.e_first_name || this.e_middle_name || this.e_last_name || this.mobile_no1 || this.e_address){
            this.submit()
          }
          this.loading = false
          this.modalInstance.hide()
        }
      })
      .catch((err) => {
        console.log(err)
      })
    },
    logout () {
      localStorage.clear()
      this.$router.push({"name": "SignInPage"})
    },
    generateWhatsappLink() {

      const constituencyMap = {
        131: 'बोईसर',
        132: 'नालासोपारा',
        133: 'वसई'
      };
      
      const candidateMap = {
        131: 'राजेश रघुनाथ पाटील',
        132: 'क्षितिज हितेंद्र ठाकूर',
        133: 'हितेंद्र विष्णू ठाकूर'
      };
      
      const constituencyName = constituencyMap[parseInt(this.constinuency)] || '';
      const candidateName = candidateMap[parseInt(this.constinuency)] || '';
      
      const message = `*Voters Slip*\n\n` +
        `*नमस्कार, ${this.voter_details.l_first_name}*\n` +
        `*विधानसभा - ${constituencyName}*\n` +
        `*उमेदवार : ${candidateName}*\n` +
        `*पार्टी : बहुजन विकास आघाडी*\n` +
        `*निशाणी : शिट्टी*\n` +
        `-----------------------------\n` +
        `Assembly: *${this.constinuency}-${constituencyName} (${this.constinuency}-${this.label})*\n` +
        `First Name: *${this.voter_details.l_first_name} (${this.voter_details.e_first_name})*\n` +
        `Middle Name: *${this.voter_details.l_middle_name} (${this.voter_details.e_middle_name})*\n` +
        `Last Name: *${this.voter_details.l_last_name} (${this.voter_details.e_last_name})*\n` +
        `Booth Address: *${this.voter_details.l_boothaddress || this.voter_details.e_boothaddress}*\n` +
        `Yadi Number: *${this.voter_details.part_no}*\n` +
        `Serial Number: *${this.voter_details.srno}*\n` +
        `Voter Epic Number: *${this.voter_details.vcardid}*\n\n` +
        `*आपला विकास बहुजन विकास*\n\n` +
        `आमदार *${candidateName} - बहुजन विकास आघाडी* उमेदवार\nनिशाणी: *शिट्टी*\n` +
        `https://voters.bahujanvikasaghadi.com/ ${constituencyName} विधानसभा आपल्या ओळखीतील लोकांचे नाव शोधा आणि त्याचे तपशील Whatsapp किंवा sms द्वारे लोकांना पाठवा.`;
      
      // URL encode the message for WhatsApp
      const encodedMessage = encodeURIComponent(message);
      
      // Create the full WhatsApp URL
      return `https://wa.me/+91${this.whatsapp_number}?text=${encodedMessage}`;
    },
    generateSmsLink() {
      const constituencyMap = {
        131: 'बोईसर',
        132: 'नालासोपारा',
        133: 'वसई'
      };
      
      const candidateMap = {
        131: 'राजेश रघुनाथ पाटील',
        132: 'क्षितिज हितेंद्र ठाकूर',
        133: 'हितेंद्र विष्णू ठाकूर'
      };
      
      const constituencyName = constituencyMap[parseInt(this.constinuency)] || '';
      const candidateName = candidateMap[parseInt(this.constinuency)] || '';

      const message = `Voters Slip\n` +
        `नमस्कार, ${this.voter_details.l_first_name}\n` +
        `विधानसभा - ${constituencyName}\n` +
        `उमेदवार : ${candidateName}\n` +
        `पार्टी : बहुजन विकास आघाडी\n` +
        `निशाणी : शिट्टी\n` +
        `-----------------------------\n` +
        `Assembly: ${this.constinuency}-${constituencyName} (${this.constinuency}-${this.label})\n` +
        `First Name: ${this.voter_details.e_first_name}\n` +
        `Middle Name: ${this.voter_details.e_middle_name}\n` +
        `Last Name: ${this.voter_details.e_last_name}\n` +
        `Booth Address: ${this.voter_details.e_boothaddress || this.voter_details.l_boothaddress}\n` +
        `Yadi Number: ${this.voter_details.part_no}\n` +
        `Serial Number: ${this.voter_details.srno}\n` +
        `Voter Epic Number: ${this.voter_details.vcardid}\n` +
        `आपला विकास बहुजन विकास`;

      // URL encode the message for SMS
      const encodedMessage = encodeURIComponent(message);
      
      // Create the SMS link
      return `sms:+91${this.sms_number}?body=${encodedMessage}`;
    },
    clear() {
      this.first_name = ""
      this.middle_name = ""
      this.last_name = ""
      this.voter_epic_number = ""
      this.yadi_number = ""
      this.area = ""
      this.ward_no = ""
      this.address = ""
      this.update_by_status = "0"
      this.dataset = []
      this.ward_exists = "NA"
      this.selected_village = []
      this.villages = []
    },
    globalClear() {
      this.globalSearchName = ""
      this.globalSearchNumber = ""
      this.globalSearchData = []
      this.selectedRowElement = []
      this.globalCurrentPage = 1
    },
    globalSearching() {
      let data = {}
      if(!this.globalSearchName && !this.globalSearchNumber){
        alert("Provide some search parameters")
        return true
      }
      if(this.globalSearchName){
        if(this.globalSearchName.length >= 3){
          data['search'] = this.globalSearchName
        }
        else{
          alert("Minimum 3 characters requried for searching by name globally")
          return true;
        }
      }
      if(this.globalSearchNumber){
        data['number'] = this.globalSearchNumber
      }
      const key = localStorage.getItem('token')
      this.globalSearchData = []
      this.selectedRowElement = []
      this.globalLoading = true
      this.globalCurrentPage = 1
      axios({
        method: "POST",
        url: "/api/v1/search/master",
        data: data,
        headers: {
          authkey: key
        }
      })
      .then((response) => {
        if(response.data.errCode == 1){
          alert(response.data.errMsg)
          this.globalLoading = false
        }
        else{
          const key = "74ce70de4186b559eb8c621e0d2f02c73453d63fef0fd7ded907b7b2a273dad5";
          const iv = "cb10ec48787215bca255b3b0f258fdf6";
          const commonKey = Buffer.from(key, 'hex');
          const commonIv = Buffer.from(iv, 'hex');

          // Create decipher
          let decipher = crypto.createDecipheriv('aes-256-cbc', commonKey, commonIv);

          // Decrypt data
          let decrypted = decipher.update(Buffer.from(response.data, 'hex'));
          decrypted = Buffer.concat([decrypted, decipher.final()]);

          // Return the decrypted data as a string
          const _data = decrypted.toString();
          this.globalSearchData = JSON.parse(_data)
          this.globalLoading = false
        }
      })
      .catch((err) => {
        console.log(err)
      })
    },
    toggleSelect(data, event) {
      if (event.target.checked) {
        // If checked, add the item to selectedRowElement
        if (!this.selectedRowElement.includes(data)) {
          this.selectedRowElement.push(data);
        }
      } else {
        // If unchecked, remove the item from selectedRowElement
        const index = this.selectedRowElement.indexOf(data);
        if (index !== -1) {
          this.selectedRowElement.splice(index, 1);
        }
      }
    },
    assignEpicNumber() {
      if(this.epicNumber){
        this.epicLoader = true
        const key = localStorage.getItem('token')
        axios({
          method: 'POST',
          url: '/api/v1/update/epic-number',
          data: {
            vcardid: this.epicNumber,
            body: this.selectedRowElement,
            constituency: this.constinuency
          },
          headers: {
            authkey: key
          }
        })
        .then((response) => {
          if(response.data.errCode == -1){
            alert("Epic number assigned successfully")
            this.epicLoader = false
          }
          else{
            alert(response.data.errMsg)
            this.epicLoader = false
          }
        })
        .catch((err) => {
          this.epicLoader = false
          console.log(err)
        })
      }
      else{
        alert("Voter Epic Number is requried to proceed")
        return true
      }
    },
    updateVerifiedNumber(number, vcardid) {
      const key = localStorage.getItem('token')
      axios({
        method: 'POST',
        url: '/api/v1/update/verified-number',
        data: {
          body: {
            verified_mobile_number: number
          },
          constituency: this.constinuency,
          vcardid: vcardid
        },
        headers: {
          authkey: key
        }
      })
      .then((response) => {
        if(response.data.errCode == -1){
          alert("Number Verified")
        }
        else{
          alert(response.data.errMsg)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    },
    updateVerifiedAddress(address, vcardid) {
      const key = localStorage.getItem('token')
      axios({
        method: 'POST',
        url: '/api/v1/update/verified-address',
        data: {
          body: {
            verified_address: address
          },
          constituency: this.constinuency,
          vcardid: vcardid
        },
        headers: {
          authkey: key
        }
      })
      .then((response) => {
        if(response.data.errCode == -1){
          alert("Address Verified")
        }
        else{
          alert(response.data.errMsg)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    },
    updateWardNo() {
      if(!this.mass_ward_no){
        alert("Please enter valid ward number")
        return false
      }
      this.mass_update_ward_loader = true
      const data = {
        vcardids: this.selectedVoters,
        constituency: this.constinuency,
        ward_no: this.mass_ward_no
      }
      const key = localStorage.getItem('token')
      axios({
        method: "POST",
        url: "/api/v1/update/mass-ward-update",
        data: data,
        headers: {
          authkey: key
        }
      })
      .then((response) => {
        if(response.data.errCode == -1){
          alert("Mass Update Completed Verified")
          this.mass_update_ward_loader = false
          this.submit()
        }
        else{
          alert(response.data.errMsg)
          this.mass_update_ward_loader = false
        }
      })
      .catch((err) => {
        console.log(err)
        this.mass_update_ward_loader = false
      })
    }
  }
};
</script>

<style>
</style>
